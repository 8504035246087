<template>
  <div v-if="data && data.item" class="product-order">
    <router-link
      :to="{
        name: 'product',
        params: {
          id: item.id,
          link: item.link,
        },
      }"
      class="product-order__content"
    >
      <ImgComponent class="product-order__img" :img="item | item_img" width="100" height="100" />
      <div class="product-order__info">
        <span class="product-order__title">
          {{ item.title }}
        </span>
        <span class="product-order__price">
          {{ data.sum | formatPrice }}
        </span>
        <span class="product-order__count"> {{ data.count }} шт. </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProductCardOrderVersionComponent",
  props: {
    data: Object,
  },
  computed: {
    item() {
      return this.data.item || {};
    },
  },
};
</script>

<style lang="stylus">
.product-order {
  width 100%
  padding 6px 0
  display flex
  align-items center
  gap: 15px 30px

  &:not(:last-child) {
    border-bottom 1px solid var(--gray-dark)
  }

  &__content {
    flex-grow 1
    display flex
    gap 15px
    align-items flex-start
    border-radius: var(--main-radius)
  }

  &__img {
    width 60px
    height 60px
    object-fit contain
    border: 1px solid var(--gray-dark);
    border-radius var(--main-radius)
    background-color var(--white)
    flex-shrink 0
  }

  &__info {
    display flex
    flex-wrap wrap
    gap: 5px 15px
  }

  &__title {
    width 100%
    font-weight 700
  }
}
</style>
