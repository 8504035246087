<template>
  <div class="default-modal order-modal">
    <button class="default-modal__close" @click="$emit('closeAll')">
      <IconComponent name="close" />
    </button>
    <div class="default-modal__header order-modal__header">
      <h2 class="default-modal__title">Заказ №{{ data.id }}</h2>
    </div>
    <div class="default-modal__body">
      <div class="order-modal__info">
        <div class="order-modal__row">
          <IconComponent class="order-modal__row-icon" name="calendar" />
          <span class="order-modal__row-title">Дата и время:</span>
          <span class="order-modal__row-value">{{ data.created_at | formatDateTime }}</span>
        </div>
        <div v-if="data.deliveryType.type === 2" class="order-modal__row">
          <IconComponent class="order-modal__row-icon" name="location-2" />
          <span class="order-modal__row-title">Адрес:</span>
          <span class="order-modal__row-value">{{ data.address }}</span>
        </div>
        <div v-if="data.deliveryType" class="order-modal__row">
          <IconComponent class="order-modal__row-icon" name="box-2" />
          <span class="order-modal__row-title">Способ получения:</span>
          <span class="order-modal__row-value">{{ data.deliveryType.name }}</span>
        </div>
        <div class="order-modal__row">
          <IconComponent class="order-modal__row-icon" name="money" />
          <span class="order-modal__row-title">Сумма:</span>
          <span class="order-modal__row-value">{{ data.sum | formatPrice }}</span>
        </div>
        <div v-if="data.paymentType" class="order-modal__row">
          <IconComponent class="order-modal__row-icon" name="cards" />
          <span class="order-modal__row-title">Тип оплаты:</span>
          <span class="order-modal__row-value">{{ data.paymentType.name }}</span>
        </div>
        <div v-if="data.status" class="order-modal__row">
          <IconComponent class="order-modal__row-icon" name="3d-rotate" />
          <span class="order-modal__row-title">Статус:</span>
          <span class="order-modal__row-value">{{ data.status.name }}</span>
        </div>
        <div class="order-modal__row">
          <IconComponent class="order-modal__row-icon" name="profile" />
          <span class="order-modal__row-title">Получатель:</span>
          <span class="order-modal__row-value">{{ getFullName() }}</span>
        </div>
        <div v-if="data.commentary" class="order-modal__row">
          <IconComponent class="order-modal__row-icon" name="comment" />
          <span class="order-modal__row-title">Комментарий:</span>
          <span class="order-modal__row-value">{{ data.commentary }}</span>
        </div>
      </div>
      <div v-if="data.items && data.items.length" class="order-modal__products">
        <span class="order-modal__products-title">Товары в заказе:</span>
        <div class="order-modal__products-list">
          <ProductCardOrderVersionComponent v-for="(item, i) in data.items" :key="i" :data="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ProductCardOrderVersionComponent from "components/product/ProductCardOrderVersionComponent.vue";

export default {
  name: "OrderModal",
  props: {
    data: Object,
  },
  methods: {
    getFullName() {
      return [this.data.name, this.data.surname].filter((i) => i).join(" ");
    },
  },
  components: { ProductCardOrderVersionComponent, IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.order-modal {
  width 100%
  max-width 600px
  padding 30px

  &__info {
    display flex
    flex-direction column
    gap: 15px
  }

  &__row {
    display grid
    grid-template-columns minmax(140px, 1fr) 3fr
    align-items baseline
    column-gap: 15px
    position relative
    padding-left 26px
    +below(540px) {
      grid-template-columns 1fr
    }

    &-icon {
      position: absolute;
      left 0
      top 2px
      width 20px
      height: 20px

      svg {
        svg(var(--body-color))
      }
    }

    &-title {
      flex-shrink 0
      font-weight 700
    }
  }

  &__products {
    display flex
    flex-direction column
    width: 100%

    &-title {
      font-weight 700
    }
  }
}
</style>
