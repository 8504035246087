<template>
  <div class="user-page-aside">
    <div class="user-page-aside__header">
      <ImgComponent class="user-page-aside__header-img" :img="user.head_img" width="200" height="200" />
      <div class="user-page-aside__header-info">
        <span class="user-page-aside__header-title"> {{ getUserFio || "" }} </span>
        <span class="user-page-aside__header-email"> {{ user.email || "" }} </span>
      </div>
    </div>
    <ul class="user-page-aside__list">
      <li v-for="(page, i) in pages" :key="i">
        <a
          @click.prevent="goToLink(page)"
          :class="{ 'user-page-aside__link--active': JSON.stringify(value) === JSON.stringify(page) }"
          class="user-page-aside__link no-hover"
        >
          <IconComponent :name="page.icon" />
          <span>{{ page.title }}</span>
        </a>
      </li>
      <li v-if="isRegistered">
        <a
          @click.prevent="logout({ store: $store, router: $router })"
          class="user-page-aside__link no-hover"
          href="#"
        >
          <IconComponent name="login" />
          <span>Выход</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import { logout } from "@/utils";

export default {
  name: "UserPageAsideComponent",
  components: { IconComponent },
  props: {
    pages: Array,
    value: Object,
  },
  computed: {
    user() {
      return this.$store.state.auth.user || {};
    },
    getUserFio() {
      return [this.user?.surname, this.user?.name].filter((a) => a).join(" ");
    },
    isRegistered() {
      return this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  methods: {
    logout,
    async goToLink(page) {
      if (this.$route.params.link !== page.route.params.link) {
        await this.$router.push(page.route);
      }
      // await this.reload();
    },
    // reload() {
    //   console.log("ok");
    //   this.$emit("reload", this.$apollo.provider, this.$store, this.$route);
    // },
  },
};
</script>

<style lang="stylus">
.user-page-aside {
  display grid
  border-radius: var(--main-radius)
  background var(--gray)
  border 1px solid var(--gray-dark)
  overflow hidden

  &__header {
    padding 10px 15px
    border-bottom: 1px solid var(--gray-dark);
    display flex
    align-items center
    gap: 15px

    &-img {
      width 80px
      height 80px
      flex-shrink 0
      border-radius 50%
    }

    &-info {
      display flex
      flex-direction column
      gap: 5px
    }

    &-title {
      font-weight: 700;
      font-size: 1.125em
      line-height: 26px;
    }

    &-email {
      font-size: 0.875em;
      line-height: 20px;
      color: var(--dark-light);
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.125em
    line-height: 140%;
  }

  &__list {
    display grid
  }

  &__link {
    padding 10px 15px
    gap 10px
    display flex
    align-items center

    &:hover {
      background var(--gray-dark)
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--dark)
      }
    }

    &--active {
      color var(--main)

      .icon svg path {
        fill var(--main)
      }
    }
  }
}
</style>
