<template>
  <div class="user-orders user-page__content">
    <div class="user-orders__wrap">
      <div class="user-orders__sorts">
        <button
          class="user-orders__sorts-item btn"
          type="button"
          v-for="(sort, i) in sortTypes"
          :key="i"
          @click="changeSort(sort)"
          :class="{
            active: JSON.stringify(activeSort) === JSON.stringify(sort),
          }"
        >
          {{ sort.title }}
        </button>
      </div>
      <LoadingComponent :loading="$store.state.loading">
        <div class="user-orders__content" v-if="showOrders">
          <div class="user-orders__table-wrap">
            <table class="user-orders__table table">
              <thead>
                <tr>
                  <th>№ Заказа</th>
                  <th>Дата заказа</th>
                  <th>Сумма заказа</th>
                  <th>Состояние</th>
                  <th>Магазин</th>
                  <th>Действие</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in orders.data" :key="i">
                  <td>{{ item.id }}</td>
                  <td>{{ item.created_at | formatDate }}</td>
                  <td>
                    {{ item.sum | formatPrice }}
                  </td>
                  <td :style="{ color: setColor(item.status.code) }">
                    {{ item.status.name }}
                  </td>
                  <td>Магазин 1</td>
                  <td><a @click.prevent="showOrderModal(item)" href="#">Просмотр</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <span v-else class="empty-block">
          <span>Заказы отсутствуют</span>
          <router-link :to="{ name: 'home' }" class="btn btn--md btn--main">На главную</router-link>
        </span>
      </LoadingComponent>
      <PaginationComponent
        v-if="showOrders && Math.ceil(total / first) > 1"
        :page="page"
        :total="Math.ceil(total / first)"
        @change="reload"
      />
    </div>
  </div>
</template>

<script>
import PaginationComponent from "components/Pagination.vue";
import OrderModal from "components/modals/components/OrderModal.vue";
import LoadingComponent from "components/LoadingComponent.vue";

export default {
  name: "OrdersPage",
  components: { LoadingComponent, PaginationComponent },
  props: {
    first: Number,
  },
  data() {
    return {
      // loading: false,
      activeSort: undefined,
      page: 1,
    };
  },
  created() {
    this.activeSort = this.sortTypes[0];
  },
  computed: {
    sortTypes() {
      return [
        {
          code: [],
          title: "Все",
        },
        {
          code: [
            this.types.CODE_ORDER_CREATED,
            this.types.CODE_ORDER_SUCCESS,
            this.types.CODE_ORDER_IN_PROCESS,
          ],
          title: "Активные",
        },
        {
          code: [this.types.CODE_ORDER_CANCELED, this.types.CODE_ORDER_FAILED],
          title: "Отмененные",
        },
        {
          code: [
            this.types.CODE_ORDER_IN_DELIVERY,
            this.types.CODE_ORDER_DELIVERED,
            this.types.CODE_ORDER_OFFLINE_PAY,
          ],
          title: "Выполненные",
        },
      ];
    },
    user() {
      return this.$store.state.auth.user;
    },
    orders() {
      return this.$store.state.auth.orders_paginate;
    },
    addresses() {
      return this.$store.state.auth.addresses;
    },
    types() {
      return this.$store.state._types;
    },
    total() {
      return this.$store.state.auth.orders_paginate.paginatorInfo.total;
    },
    showOrders() {
      return this.orders && this.orders.data && this.orders.data.length;
    },
  },
  methods: {
    setColor(code) {
      if (code === 2 || code === 7 || code === 6 || code === 5) {
        return "#50B945";
      } else if (code === 3) {
        return "#9F9F9F";
      } else {
        return "#DB3636";
      }
    },
    showOrderModal(data) {
      this.$store.state._modals.push({
        component: OrderModal,
        options: {
          data,
        },
      });
    },
    async changeSort(sort) {
      this.activeSort = sort;
      await this.reload();
    },
    // stopLoading() {
    //   this.loading = false;
    // },
    reload(page = 1) {
      this.page = page;
      this.$store.state.loading = true;
      this.$emit("reload", this.$apollo.provider, this.$store, this.$route, {
        status: this.activeSort.code,
        page,
      });
    },
  },
};
</script>

<style lang="stylus">
.user-orders {
  &__points {
    display flex
    align-items center
    justify-content space-between
    gap: 20px
    padding 20px 30px
    border-radius var(--big-radius)
    background: var(--white)
    font-size: 1.75em
    line-height: 38px;
    text-transform: uppercase;
    font-weight 700
    +below(480px) {
      flex-direction column
      align-items flex-start
      gap: 15px
      font-size: 1.375em
    }

    &-value {
      background: var(--blue)
      border-radius: var(--main-radius)
      padding 10px 15px
      color var(--white)
    }
  }

  &__wrap {
    display flex
    flex-direction column
    align-items flex-start
    gap: 30px
  }

  &__sorts {
    background: var(--gray);
    border-radius: var(--main-radius)
    padding 5px
    display flex
    overflow auto
    gap: 10px
    +below(680px) {
      flex-direction column
      width 100%
      gap: 10px
    }

    &-item {
      flex-shrink 0
      font-weight: 700;
      padding 13px 30px
      border-radius: 10px;
      transition var(--transition)
      font-size 1em

      &:not(.active):hover {
        background var(--gray-dark)
      }

      &.active {
        color var(--white)
        background var(--main)
      }
    }
  }

  &__content {
    display flex
    flex-direction column
    gap: 20px
  }

  &__table {
    &-wrap {
      width 100%
      overflow auto
      display flex
      flex-direction column
      gap: 30px
      padding-bottom 10px
    }
  }
}
</style>
