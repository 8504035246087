<template>
  <div class="default-menu">
    <div class="default-menu__header">
      <div class="default-menu__header-top">
        <span class="default-menu__title">Навигация</span>
        <button type="button" class="default-menu__close" @click="$emit('close')">
          <IconComponent name="close" />
        </button>
      </div>
    </div>
    <div class="default-menu__body">
      <ul class="default-menu__links">
        <li v-for="(item, i) in list" :key="i">
          <router-link :to="item.route" class="default-menu__link">
            <span>{{ item.title }}</span>
            <IconComponent name="arrow-right-1" />
          </router-link>
        </li>
        <li v-if="isRegistered">
          <a class="default-menu__link" @click.prevent="logout({ store: $store, router: $router })" href="#">
            Выйти
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import { logout } from "@/utils";

export default {
  name: "UserMenu",
  data() {
    return {
      list: [
        {
          title: "Регистрационные данные",
          route: { name: "user", params: { link: "profile" } },
        },
        {
          title: "Адрес доставки",
          route: { name: "user", params: { link: "address" } },
        },
        {
          title: "История покупок",
          route: { name: "user", params: { link: "orders" } },
        },
        {
          title: "Мой список желаний",
          route: { name: "user", params: { link: "wishlist" } },
        },
      ],
    };
  },
  computed: {
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  methods: {
    logout,
  },
  components: { IconComponent },
};
</script>
