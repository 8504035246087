<template>
  <div class="user-settings user-page__content">
    <form @submit.prevent="profileUpdate" class="user-settings__form form">
      <UserCardComponent :user-image="form.head_img.value" @change="handleImageUpload" />
      <div class="form__row">
        <div class="form__col-4">
          <InputComponent
            v-model.trim="form.surname.value"
            :error="form.surname.error"
            placeholder="Фамилия"
            required
          />
        </div>
        <div class="form__col-4">
          <InputComponent
            v-model.trim="form.name.value"
            :error="form.name.error"
            placeholder="Имя"
            required
          />
        </div>
        <div class="form__col-4">
          <InputComponent
            v-model.trim="form.patronymic.value"
            :error="form.patronymic.error"
            placeholder="Отчество"
          />
        </div>
        <div class="form__col-6">
          <InputComponent
            v-model.trim="form.email.value"
            :error="form.email.error"
            placeholder="Email"
            modified
            required
          />
        </div>
        <div class="form__col-6">
          <button type="submit" class="btn btn--lg btn--main">
            <span>Сохранить</span>
            <IconComponent v-if="profileUpdateLoading" name="loading" />
          </button>
        </div>
      </div>
    </form>
    <form @submit.prevent="phoneUpdate" class="form">
      <div class="form__row">
        <div class="form__col-6">
          <InputComponent
            v-model="form.phone.value"
            :error="form.phone.error"
            placeholder="Телефон"
            mask="+7 (###) ### - ## - ##"
            required
            is-phone
          />
        </div>
        <div class="form__col-6">
          <button type="submit" class="btn btn--lg btn--main">
            <span>Сохранить</span>
            <IconComponent v-if="phoneUpdateLoading" name="loading" />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import USER_PROFILE_UPDATE from "gql/auth/UserProfileUpdate.graphql";
import USER_PHONE_UPDATE from "gql/auth/UserPhoneUpdate.graphql";
import UserCardComponent from "../../components/UserCardComponent.vue";
import IconComponent from "components/IconComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import { parseGqlErrors, resetErrors } from "@/utils";

export default {
  name: "SettingsPage",
  data() {
    return {
      subscription: true,
      profileUpdateLoading: false,
      phoneUpdateLoading: false,
      form: {
        surname: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        name: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        patronymic: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        head_img: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  mounted() {
    this.setProfileData();
  },
  watch: {
    "$store.state.auth.user"() {
      this.setProfileData();
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    parseGqlErrors,
    resetErrors,
    handleImageUpload(event) {
      this.form.head_img.value = event;
    },
    setProfileData() {
      if (this.user) {
        this.form.name.value = this.user.name;
        this.form.surname.value = this.user.surname;
        this.form.patronymic.value = this.user.patronymic;
        this.form.email.value = this.user.email;
        this.form.phone.value = this.user.phone;
        this.form.head_img.value = this.user.head_img;
      }
    },
    async profileUpdate() {
      if (!this.profileUpdateLoading) {
        this.profileUpdateLoading = true;
        if (this.form.head_img.value) {
          delete this.form.head_img.value.__typename;
        }
        this.resetErrors("form");
        let variables = {
          surname: this.form.surname.value || "",
          name: this.form.name.value || "",
          patronymic: this.form.patronymic.value || "",
          email: this.form.email.value || null,
          head_img: this.form.head_img.value || undefined,
        };
        await this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PROFILE_UPDATE,
            variables,
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.UserProfileUpdate) {
              this.$store.state.auth.user = data.UserProfileUpdate.user;
              this.$store.state._modals.push({
                component: AlertModal,
                options: {
                  title: "Изменение профиля",
                  message: data.UserProfileUpdate.message,
                  center: true,
                },
              });
            }
            this.profileUpdateLoading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.profileUpdateLoading = false;
            this.parseGqlErrors(graphQLErrors, "form");
          });
      }
    },
    phoneUpdate() {
      if (!this.phoneUpdateLoading) {
        this.phoneUpdateLoading = true;
        this.resetErrors("form");
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PHONE_UPDATE,
            variables: {
              phone: this.form.phone.value ? this.form.phone.value?.replace(/[^\d+]/g, "") : undefined,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(() => {
            this.phoneUpdateLoading = false;
            // показать модалку с вводом кода
            this.$store.state._modals.push({
              component: LoginModal,
              options: {
                phone: this.form.phone.value,
                initStep: 1,
                title: "Подтверждение номера",
                submitBtn: "Сохранить",
              },
            });
          })
          .catch(({ graphQLErrors }) => {
            this.phoneUpdateLoading = false;
            this.parseGqlErrors(graphQLErrors, "form");
          });
      }
    },
  },
  components: { IconComponent, UserCardComponent, InputComponent },
};
</script>

<style lang="stylus">
.user-settings {
  grid-gap: 20px

  &__form {
    border-radius var(--big-radius)
    background var(--white)
    grid-gap 20px
    +below(540px) {
      grid-gap 15px
    }
  }

  &__img {
    display flex
    align-items center
    gap: 15px

    img {
      width: 80px
      height: 80px
    }

    .btn {
      padding 5px 15px
      background var(--gray)
      color var(--dark-light)
      border-radius: var(--small-radius)
    }
  }

  .form__row {
    .input {
      width 100%
    }

    .form__col-4 {
      +below(680px) {
        flex-basis calc(100% - 16px)
      }
    }

    .form__col-6 {
      +below(860px) {
        gap 16px
      }
      +below(680px) {
        flex-basis calc(100% - 16px)
      }
    }

    .btn {
      margin-left auto
    }
  }
}
</style>
