<template>
  <div v-if="loading" class="loading">
    <div class="empty-block">
      <IconComponent name="loading" />
    </div>
  </div>
  <div v-else class="loading-content">
    <slot />
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "LoadingComponent",
  components: { IconComponent },
  props: {
    loading: Boolean,
  },
};
</script>

<style lang="stylus">
.loading {
  width 100%

  &-content {
    width 100%
  }

  .icon {
    width 100px
    height 100px

    svg circle {
      stroke var(--black)
    }
  }
}
</style>
