<template>
  <div class="user-page__profile">
    <ProfileBonusComponent />
    <router-link class="user-page__profile-link no-hover" :to="{ name: 'user', params: { link: 'orders' } }">
      <span class="user-page__profile-link-title">Ваши заказы</span>
      <IconComponent class="user-page__profile-link-icon" name="bag" />
    </router-link>
    <router-link
      class="user-page__profile-link no-hover"
      :to="{ name: 'user', params: { link: 'wishlist' } }"
    >
      <span class="user-page__profile-link-title">Избранное</span>
      <IconComponent class="user-page__profile-link-icon" name="heart" />
    </router-link>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ProfileBonusComponent from "./components/ProfileBonusComponent.vue";
export default {
  name: "ProfilePage",
  components: {
    ProfileBonusComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.user-page {
  &__profile {
    display grid
    //grid-template-columns 1fr 1fr
    grid-template-columns repeat(3, 1fr)
    grid-gap var(--gap)
    grid-template-areas "a a b" "a a c"
    +below(1350px) {
      grid-template-columns 1fr 1fr
      grid-template-areas "a a" "b c"
    }
    +below(540px) {
      grid-template-columns 1fr
      grid-template-areas "a" "b" "c"
    }

    &-link {
      position relative
      background: var(--white);
      border-radius: var(--big-radius);
      padding 30px
      display flex
      flex-direction column
      justify-content space-between
      align-items center
      gap: 15px
      overflow hidden
      background var(--gray)

      &:hover {
        background var(--gray-dark)
      }

      &-title {
        font-weight: 900;
        font-size: 1.25em
        line-height: 24px;
        text-transform: uppercase;
      }

      &-icon {
        width: 60px
        height: 60px

        svg path {
          fill var(--main)
        }
      }
    }
  }
}
</style>
