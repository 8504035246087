<template>
  <div class="cropper">
    <div class="cropper__header">
      <h3 class="default-modal__title">Обрезка изображения</h3>
    </div>
    <div class="cropper__body">
      <div v-if="data.imageBase64">
        <vue-cropper
          class="cropper__container"
          :viewMode="1"
          ref="cropper"
          :src="data.imageBase64"
          alt="Изображение"
        />
        <div class="cropper__buttons" v-if="!loading">
          <button class="btn btn--md btn--main" type="button" @click="$refs.cropper.move(10, 0)">
            <IconComponent name="cropper-left" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="$refs.cropper.move(0, 10)">
            <IconComponent name="cropper-up" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="$refs.cropper.move(0, -10)">
            <IconComponent name="cropper-down" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="$refs.cropper.move(-10, 0)">
            <IconComponent name="cropper-right" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="$refs.cropper.rotate(-45)">
            <IconComponent name="cropper-rotate-left" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="$refs.cropper.rotate(45)">
            <IconComponent name="cropper-rotate-right" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="scaleHorizontal">
            <IconComponent name="cropper-flip-horizontal" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="scaleVertical">
            <IconComponent name="cropper-flip-vertical" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="$refs.cropper.relativeZoom(0.1)">
            <IconComponent name="cropper-zoom-in" />
          </button>
          <button class="btn btn--md btn--main" type="button" @click="$refs.cropper.relativeZoom(-0.1)">
            <IconComponent name="cropper-zoom-out" />
          </button>
          <button class="btn btn--mda btn--main" type="button" @click="$refs.cropper.reset()">
            <IconComponent name="cropper-reset" />
          </button>
        </div>
      </div>
    </div>
    <div class="cropper__footer">
      <button class="btn btn--main btn--lg" type="button" @click="cropImage">
        <span>Обрезать</span>
        <IconComponent v-if="loading" name="loading" />
      </button>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import IMAGE_UPLOAD_CROP from "gql/mutations/ImageUploadCrop.graphql";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CropImageComponent",
  props: {
    data: Object,
    callback: Function,
  },
  components: {
    VueCropper,
    IconComponent,
  },
  data() {
    return {
      loading: false,
      scaleH: -1,
      scaleV: -1,
    };
  },
  methods: {
    /**
     * Отражение по горизонтали
     */
    scaleHorizontal() {
      this.$refs.cropper.scaleX(this.scaleH);
      this.scaleH = this.scaleH > 0 ? -1 : 1;
    },
    /**
     * Отражение по вертикали
     */
    scaleVertical() {
      this.$refs.cropper.scaleY(this.scaleV);
      this.scaleV = this.scaleV > 0 ? -1 : 1;
    },
    /**
     * Обрезка изображения
     */
    cropImage() {
      if (!this.loading) {
        this.loading = true;
        let variables = {
          image: this.data.image,
        };
        let crop = this.$refs.cropper.getData();
        Object.keys(crop).forEach((key) => {
          variables[key] = parseInt(crop[key]);
        });
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: IMAGE_UPLOAD_CROP,
            variables: variables,
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            this.loading = false;
            this.callback(data);
            this.$emit("close");
            this.$notify({
              title: "Внимание",
              text: "Не забудьте сохранить, чтобы не потерять изменения",
              type: "warn",
            });
          })
          .catch((error) => {
            console.error("error", error);
            this.$notify({
              title: "Произошла ошибка",
              text: "При обрезке изображения произошла ошибка \n Перезагрузите страницу и попробуйте еще раз",
              type: "error",
              duration: 6000,
            });
            this.$emit("close");
          });
      }
    },
  },
};
</script>
<style lang="stylus">
@import "~cropperjs/dist/cropper.css";
.cropper {
  max-width: 588px;
  width 100%
  z-index 2
  background: var(--white);
  border-radius: var(--big-radius);
  display grid
  grid-gap 20px

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 25px;
    padding-top 20px
  }

  &__title {
    font-size 1.375em
    font-weight: 500;
    margin: 0;
  }

  &__close {
    background none
    padding 0
    border 0
    cursor pointer
    absolute right 25px top 20px

    .icon {
      width 28px
      height 28px

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__footer
  &__buttons {
    display flex
    justify-content flex-end
    flex-wrap wrap
    gap 5px
    padding 0 20px
  }

  &__buttons {
    padding-top 20px

    .btn {
      padding 10px
      width: 45px
      height: 45px
    }
  }

  &__footer {
    padding-bottom 20px
  }
}
</style>
