<template>
  <div class="user-card">
    <ImgComponent class="user-card__image" :src="$store.state._env.MEDIA_ENDPOINT + image" v-if="image" />
    <ImgComponent class="user-card__image" :img="userImage" width="200" height="200" v-else />
    <div class="user-card__content">
      <button @click.prevent="$refs.input.click()" type="button" class="btn btn--sm btn--gray">
        Изменить
      </button>
      <button @click.prevent="removeImg" type="button" class="btn btn--sm btn--gray user-card__remove-btn">
        Удалить
      </button>
    </div>
    <input type="file" accept="image/*" ref="input" v-show="false" @input="showCropperModal" />
  </div>
</template>

<script>
import CropImageModal from "components/modals/components/CropImageModal.vue";

export default {
  name: "UserCardComponent",
  props: {
    userImage: Object,
  },
  data() {
    return {
      image: null,
    };
  },
  methods: {
    removeImg() {
      this.image = null;
      this.$emit("change", null);
      this.$notify({
        title: "Внимание",
        text: "Не забудьте сохранить, чтобы не потерять изменения",
        type: "warn",
        duration: 6000,
      });
    },
    handleCallback(e) {
      this.$refs.input.type = "";
      this.$refs.input.type = "file";
      this.image = e.ImageUploadCrop.path;
      let image = e.ImageUploadCrop;
      delete image.__typename;
      delete image.type;
      this.$emit("change", e.ImageUploadCrop);
    },
    showCropperModal(e) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.$store.state._modals.push({
          component: CropImageModal,
          options: {
            data: {
              imageBase64: reader.result,
              image: e.target.files[0],
            },
            callback: this.handleCallback,
          },
        });
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    },
  },
};
</script>

<style lang="stylus">
.user-card {
  display flex
  align-items center
  gap 15px

  &__remove-btn {
    color var(--red)
  }

  &__image {
    width 80px
    height 80px
    border-radius 50%
    object-fit cover
    object-position center
    border 1px solid var(--gray-dark)
  }

  &__content {
    display flex
    align-items center
    gap 15px
  }

  &__title {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--body-color);
  }

  &__total {
    display flex
    flex-direction column
    justify-content flex-start
    align-items flex-end
    +below(640px) {
      justify-content center
      align-items center
    }

    &-title {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: var(--body-color);
      margin 0
    }

    &-value {
      font-weight: normal;
      font-size: 2.25em;
      line-height: 50px;
      display: flex;
      align-items: center;
      color: var(--body-color);
      margin 0
    }
  }
}
</style>
