import { render, staticRenderFns } from "./ProfileBonusComponent.vue?vue&type=template&id=6ace7fda&"
import script from "./ProfileBonusComponent.vue?vue&type=script&lang=js&"
export * from "./ProfileBonusComponent.vue?vue&type=script&lang=js&"
import style0 from "./ProfileBonusComponent.vue?vue&type=style&index=0&id=6ace7fda&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports