<template>
  <LoadingComponent :loading="$store.state.loading">
    <div class="user-wishlist user-page__content">
      <template v-if="favorites && favorites.length">
        <button @click="clearWishListModal" class="user-wishlist__clear btn btn--md btn--green-hollow">
          <IconComponent name="trash" />
          <span>Очистить избранное</span>
        </button>
        <ProductsListComponent v-if="favorites.length" :items="favorites" />
      </template>
      <span v-else class="empty-block"> Избранные товары отсутствуют </span>
    </div>
  </LoadingComponent>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ProductsListComponent from "components/ProductsListComponent.vue";
import LoadingComponent from "components/LoadingComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";

export default {
  name: "WishlistPage",
  computed: {
    favorites() {
      return this.$store.state.auth.favorites.filter((item) => item.id).map((item) => item.item);
    },
  },
  methods: {
    clearWishListModal() {
      this.showAlertModal({
        title: "Удалить всё из избранного?",
        message: "Просто уточняем, а то вдруг вы случайно задели кнопку",
        isConfirm: true,
        closeBtnTitle: "Оставить",
        confirmBtnTitle: "Удалить",
        closeBtnClass: "btn--gray",
        confirmBtnClass: "btn--red",
        callback: this.deleteAll,
      });
    },
    showAlertModal(options, callback) {
      this.$store.state._modals.push({
        component: AlertModal,
        options: options,
        callback,
      });
    },
    /**
     * Удаление всех товаров из избранных
     * @returns {Promise<void>}
     */
    async deleteAll() {
      await this.$store
        .dispatch("FAVORITE_DELETE_ALL", {
          apollo: this.$apollo,
        })
        .then(() => {
          this.$store.dispatch("GET_FAVORITES", {
            apollo: this.$apollo,
          });
        });
    },
  },
  components: { LoadingComponent, ProductsListComponent, IconComponent },
};
</script>

<style lang="stylus">
.user-wishlist {
  &__clear {
    align-self flex-end
    gap: 15px
    padding 0 30px
    color var(--green)
    +below(480px) {
      width 100%
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--green)
      }
    }

    &:hover {
      .icon svg path {
        fill var(--white)
      }
    }
  }
}
</style>
