<template>
  <div class="user-page page">
    <BreadcrumbsComponent @back.prevent :title="activePage.title" />
    <div class="container-padding">
      <div class="user-page__body">
        <UserPageAsideComponent v-model="activePage" :pages="pages" />
        <component
          ref="page"
          :is="activePage.page"
          v-if="activePage"
          @reload="reload"
          :first="activePage.first"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserPageAsideComponent from "./components/UserPageAsideComponent.vue";
import ProfilePage from "./pages/profile/index.vue";
import SettingsPage from "./pages/settings/index.vue";
import OrdersPage from "./pages/orders/index.vue";
import AddressPage from "./pages/address/index.vue";
import WishlistPage from "./pages/wishlist/index.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import UserMenu from "components/menus/components/UserMenu.vue";
import USER_ORDERS_PAGE from "@/graphql/pages/user/UserOrdersPage.graphql";
import USER_FAVORITES_PAGE from "@/graphql/pages/user/UserFavoritesPage.graphql";
import USER_ADDRESSES_PAGE from "@/graphql/pages/user/UserAddressesPage.graphql";
import DEFAULT_PAGE from "@/graphql/pages/DefaultPage.graphql";

// Страницы
let pages = [
  {
    link: "profile",
    title: "Личный кабинет",
    route: { name: "user", params: { link: "profile" } },
    page: ProfilePage,
    query: DEFAULT_PAGE,
    icon: "user",
    // Здесь грузится только история покупок т.к. личные данные грузятся в entry-server.js
    // без этих данных вход на эту страницу невозможен/не имеет смысла
  },
  {
    link: "orders",
    title: "Заказы",
    route: { name: "user", params: { link: "orders" } },
    page: OrdersPage,
    query: USER_ORDERS_PAGE,
    first: 10,
    icon: "bag",
  },
  {
    link: "wishlist",
    title: "Избранное",
    route: { name: "user", params: { link: "wishlist" } },
    page: WishlistPage,
    query: USER_FAVORITES_PAGE,
    icon: "heart",
  },
  {
    link: "address",
    title: "Адреса доставки",
    route: { name: "user", params: { link: "address" } },
    page: AddressPage,
    query: USER_ADDRESSES_PAGE,
    icon: "routing",
  },
  {
    link: "settings",
    title: "Настройки",
    route: { name: "user", params: { link: "settings" } },
    page: SettingsPage,
    query: DEFAULT_PAGE,
    icon: "settings",
  },
];

async function load(apollo, store, route, variables = {}) {
  let activePage = pages.find((item) => item.link === route.params.link) || pages[0];
  variables.first = activePage.first;
  if (activePage.query) {
    store.state.loading = true;
    apollo.defaultClient
      .query({
        query: activePage.query,
        variables: variables,
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: "Bearer " + store.state.apollo_token,
          },
        },
      })
      .then(({ data }) => {
        // console.log(data);
        store.state.auth.bonus = data.bonus;
        console.log(store.state.auth.bonus, "fs");
        if (data.my_orders_paginate) store.state.auth.orders_paginate = data.my_orders_paginate;
        // store.state.auth.favorites_paginate = data.products_paginate;
        if (data.addresses) store.state.auth.addresses = data.addresses;
        if (data.favorites) store.state.auth.favorites_ids = data.favorites.map((item) => item.item_id);
        store.state.auth.favorites = data.favorites;
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
        store.state.loading = false;
      })
      .catch(() => {
        console.log("error");
        store.state.loading = false;
      });
  }
}

export default {
  name: "UserPage",
  // async asyncData({ apollo, store, route }) {
  //   await load(apollo, store, route);
  // },
  mounted() {
    this.load(this.$apollo.provider, this.$store, this.$route);
  },
  data() {
    return {
      pages: pages,
    };
  },
  computed: {
    orders() {
      return this.$store.state.auth.orders;
    },
    activePage() {
      return this.pages.find((page) => page.link === this.$route.params.link) || this.pages[0];
    },
  },
  methods: {
    showMenu() {
      this.$store.state._menus.push({
        component: UserMenu,
        options: {},
      });
    },
    async reload() {
      await this.load(...arguments);
      // this.$refs.page.stopLoading();
    },
    load,
  },
  components: { BreadcrumbsComponent, UserPageAsideComponent },
  metaInfo() {
    return {
      title: this.activePage.title || "Личный кабинет",
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/table.styl"
.user-page {
  display grid
  align-items start
  grid-gap 20px
  +below(680px) {
    padding 30px 0
  }

  &__body {
    display grid
    align-items start
    grid-template-columns minmax(270px, 303px) 1fr
    grid-gap 30px
    max-width var(--main_width)
    width 100%
    margin 0 auto
    +below(1024px) {
      grid-template-columns minmax(270px, 4fr) 8fr
    }
    +below(860px) {
      grid-template-columns 1fr
    }
  }

  &__nav-btn {
    +above(861px) {
      display none
    }
  }

  &__content {
    display flex
    flex-direction column
    grid-gap 30px
  }

  &__footer {
    display: flex;
    justify-content flex-end
    align-items center

    .btn {
      +below(420px) {
        width 100%
      }
    }
  }
}
</style>
