<template>
  <LoadingComponent :loading="loading">
    <div class="user-addresses user-page__content">
      <div v-if="addresses && addresses.length" class="table-wrap">
        <table class="table">
          <thead>
            <tr>
              <th>№</th>
              <th>Адрес</th>
              <th class="align-center">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(a, i) in addresses" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ a.address }}</td>
              <td class="align-center">
                <a @click.prevent="deleteAddress(a.id)" href="#"> Удалить </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-else class="empty-block">Адреса отсутствуют</span>
      <div class="user-page__footer">
        <button @click="showAddressModal" type="button" class="btn btn--md btn--main">Добавить</button>
      </div>
    </div>
  </LoadingComponent>
</template>

<script>
import AddressModal from "components/modals/components/AddressModal.vue";
import ADDRESS_DELETE from "gql/mutations/AddressDelete.graphql";
import LoadingComponent from "components/LoadingComponent.vue";

export default {
  name: "AddressPage",
  components: { LoadingComponent },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    addresses() {
      return this.$store.state.auth.addresses;
    },
  },
  methods: {
    deleteAddress(id) {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: ADDRESS_DELETE,
            variables: {
              id: id,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(() => {
            this.$emit("reload", this.$apolloProvider, this.$store, this.$route);
          });
      }
    },
    showAddressModal() {
      this.$store.state._modals.push({
        component: AddressModal,
        options: {},
      });
    },
    getFullAddress(address) {
      return [address.address, address.apartment, address.entrance, address.intercom, address.floor]
        .filter((a) => a)
        .join(", ");
    },
    stopLoading() {
      this.loading = false;
    },
  },
};
</script>

<style lang="stylus">
.user-page__address {
  &-list {
    width 100%
    display grid

    li {
      padding 13px 20px
      border-bottom 1px solid var(--gray-3-dark)
      display flex
      align-items center
      justify-content space-between
      gap 15px
    }
  }

  &-actions {
    flex-shrink 0
    display flex
    gap 15px
  }

  &-action {
    display flex
    background none
    border none
    cursor pointer

    .icon,
    .file-icon {
      width: 20px
      height 20px

      svg path {
        stroke var(--brown)
        transition var(--transition)
      }

      svg circle {
        stroke var(--brown)
      }
    }

    &:hover svg {
      path, circle {
        stroke var(--brown-light)
      }
    }
  }

  &__footer {
    display: flex;
    justify-content flex-end
    align-items center
  }
}
</style>
