<template>
  <div class="profile-bonus">
    <img src="/static/images/bonus-card-bg.jpg" alt="фон бонусной карты" class="profile-bonus__image" />
    <div class="profile-bonus__content">
      <div class="profile-bonus__header">
        <img src="/static/svg/logo.svg" alt="логотип проекта" class="profile-bonus__logo" />
        <span class="profile-bonus__percent">3%</span>
      </div>
      <div class="profile-bonus__footer">
        <span class="profile-bonus__subtitle">Бонусная карта</span>
        <div class="profile-bonus__points">
          <span class="profile-bonus__count">{{ bonus?.count }}</span>
          <span>баллов</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileBonusComponent",
  computed: {
    bonus() {
      return this.$store.state.auth.bonus !== null ? this.$store.state.auth.bonus : 0;
    },
  },
};
</script>

<style lang="stylus">
.profile-bonus {
  grid-area a
  width 100%
  height 100%
  position relative

  &__content {
    absolute left 0 top 0
    padding 50px
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content space-between
    +below(600px) {
      padding 25px
    }
  }

  &__header {
    display flex
    justify-content space-between
    align-items center
  }

  &__logo {
    max-width 224px
    max-height 27px
    +below(600px) {
      max-width 150px
    }
    +below(450px) {
      max-width 100px
    }
  }

  &__percent {
    font-size 2rem
    font-weight 700
    line-height normal
    color var(--green)
    +below(600px) {
      font-size 1.125rem
    }
  }

  &__footer {
    display flex
    justify-content space-between
    align-items flex-end
  }

  &__subtitle {
    font-size 1.125rem
    font-weight 700
    line-height 26px
    +below(600px) {
      font-size .625rem
      line-height 1rem
    }
  }

  &__points {
    display flex
    flex-direction column
    align-items flex-end
    color var(--white)
  }

  &__count {
    font-size 3.75rem
    font-weight 900
    line-height normal
    +below(600px) {
      font-size 2.125rem
    }
  }
}
</style>
