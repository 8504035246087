var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.data.item)?_c('div',{staticClass:"product-order"},[_c('router-link',{staticClass:"product-order__content",attrs:{"to":{
      name: 'product',
      params: {
        id: _vm.item.id,
        link: _vm.item.link,
      },
    }}},[_c('ImgComponent',{staticClass:"product-order__img",attrs:{"img":_vm._f("item_img")(_vm.item),"width":"100","height":"100"}}),_c('div',{staticClass:"product-order__info"},[_c('span',{staticClass:"product-order__title"},[_vm._v("\n        "+_vm._s(_vm.item.title)+"\n      ")]),_c('span',{staticClass:"product-order__price"},[_vm._v("\n        "+_vm._s(_vm._f("formatPrice")(_vm.data.sum))+"\n      ")]),_c('span',{staticClass:"product-order__count"},[_vm._v(" "+_vm._s(_vm.data.count)+" шт. ")])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }